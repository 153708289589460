/* eslint-disable no-restricted-globals */
import { useEffect, useState, useRef } from 'react';
import { get } from 'kaf-client';
import { subscribe } from 'xns-client';
import { getSitedefinition } from 'kmf-sitedefinition';
import { JURISDICTIONS } from '../constants/index';
import { formatResponse } from '../utils';

const WALLIT_MAINBALANCE = '/wallitt/mainbalance';
const BALANCE_LIMIT_SECURE = '/balance-limit/secure';
const OVERDRAFT_BALANCE_UPDATED_XNS_NAME = 'OverdraftBalanceUpdated';

export default function useRestrictedBalance() {
  const [overdraftBalance, setOverdraftBalance] = useState({
    amount: 0,
    currencyCode: 'EUR',
  });
  const [shouldRender, setShouldRender] = useState(false);

  const subscription = useRef();

  const updateRestrictedBalance = (amount, currencyCode) => {
    if (!isNaN(amount) && currencyCode) {
      setOverdraftBalance({ amount, currencyCode });
    }
  };

  const handleMainBalanceRequest = (data) => {
    if (data?.balance) {
      const { overdraft, currencyCode } = data.balance;
      updateRestrictedBalance(overdraft, currencyCode);
    }
  };

  const getInitBalance = () => {
    get(WALLIT_MAINBALANCE).then(formatResponse).then(handleMainBalanceRequest);
  };

  const balanceUpdatedCallBack = (data) => {
    if (data?.body) {
      const { overdraftBalance: overdraft, currency } = data.body;
      updateRestrictedBalance(overdraft, currency);
    }
  };

  const userHasActiveBalanceLimit = async () => {
    const response = await get(BALANCE_LIMIT_SECURE).then(formatResponse);

    return !!response?.active;
  };

  const shouldRenderOverdraftBalance = async () => {
    const { jurisdiction } = getSitedefinition();
    const { NL, DE } = JURISDICTIONS;

    // Setting a balance limit is mandatory for the NL jurisdiction but optional for DE.
    return (
      jurisdiction === NL ||
      (jurisdiction === DE && userHasActiveBalanceLimit())
    );
  };

  const handleOverdraftBalance = async () => {
    if (await shouldRenderOverdraftBalance()) {
      setShouldRender(true);
      getInitBalance();
      subscription.current = subscribe(
        OVERDRAFT_BALANCE_UPDATED_XNS_NAME,
        balanceUpdatedCallBack
      );
    }
  };

  useEffect(() => {
    handleOverdraftBalance();
    return () =>
      subscription.current?.unsubscribe && subscription.current.unsubscribe();
  }, []);

  return {
    shouldRender,
    overdraftBalance,
  };
}

import React from 'react';
import { useTranslation } from 'kmf-i18n';
import RegulatoryItem from '../RegulatoryItem';
import Balance from '../Balance';
import useToggle from '../../../../../../hooks/useToggle';
import useOverdraftBalanceController from '../../../../../../hooks/useOverdraftBalanceController';

const RegulatoryItems = () => {
  const { shouldRender, overdraftBalance } = useOverdraftBalanceController();
  const { on: showBalance, toggle: toggleBalanceVisibility } = useToggle();

  const { raw } = useTranslation('game-play');

  if (!shouldRender) return null;

  const { amount, currencyCode } = overdraftBalance;

  return (
    <RegulatoryItem
      onClick={toggleBalanceVisibility}
      label={raw('gameBar.restrictedBalance')}
      dataTestName="overdraft-balance"
    >
      <Balance
        amount={amount}
        currency={currencyCode}
        showBalance={showBalance}
      />
    </RegulatoryItem>
  );
};

export default RegulatoryItems;
